html{height: 100%;
	box-sizing: border-box;
	overflow-x: hidden;
	scroll-behavior: smooth;}



#bouton_menu {display: none;
  padding-top: 16px;
  cursor: pointer;
  padding-right: 16px;} 
  

.loader {
  border: 10px solid #f3f3f3;
  margin: auto;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

body {min-height: 100%;
  margin: 0;
  padding: 0;
  color: black;
  background-color: white;
  font-family: 'poppins', Helvetica, sans-serif;
  font-size: 16px;
  position: relative;
  overflow-x: hidden;}


#nav {text-align: center;}


#menu {list-style: none;
    display: flex;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;}
  
    

    
.link {margin-right: 22px;
    margin-left: 22px;
    position:relative;}
  
    
.link::after{position: absolute;
    bottom: 4px;
    left: 0;
    content: "";
    width: 0%;
    height: 4px;
    border-radius: 20%;
    background-color: white;
    transition: width 0.3s ease;}
  
  
.link:hover.link::after {width: 100%;}
  
    
#menu li a{
    text-shadow: 0 2px 4px rgba(0,0,0,.5);
    display:block;
    padding-bottom: 16px;
    padding-top: 16px;
    font-family: lilita;
    color: white;
    font-size: 1.3rem;
    text-decoration: none;}



#collection {width: 100vw;
	height: 50.57vw;
	overflow: hidden;
    position: relative;
	background-color: #5ce1e6;}

	
#collection img {position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;}
  

#gem {animation: animate 2.5s ease-in-out 1s alternate infinite;}

    @keyframes animate { from {transform: translateY(0px); } to {transform: translateY(20px); } }
    
#sgem {animation: animate1 2.5s ease-in-out 1s alternate infinite;}
      
    @keyframes animate1 { from {transform: translateY(0px); } to {transform: translateY(-20px); } }

.box {position: absolute;
      bottom: -1px;
      height: 40px;
      width: 100%;
      background-image: url("static/media/bordure.svg");
        background-size: cover;	
        background-repeat: no-repeat;
        background-position: bottom;
      left: 0;}
    
    
.box2 {position: absolute;
        bottom: 0px;
        height: 40px;
        width: 100%;
        background-image: url("static/media/bordure.png");
          background-size: cover;	
          background-repeat: no-repeat;
          background-position: bottom;
        left: 0;}


#arcadia{color: #505079;
          background-color: white;
          width: 100%;}
          
          
#arcadia1 {max-width: 1440px; 
          margin: auto;
          align-items: center;
          display: flex; 
          overflow: hidden;
          padding-left: 60px; 
          padding-top: 90px;
          padding-bottom: 90px;
            padding-right: 60px;}
        
#arcadia15 {width: 50%; 
          text-align: center;}
        
        
#arcadia2 {width: 46%; 
          padding-left: 2%; 
          padding-right: 2%;}

          
#arcadia h2 {color: #fb8842;
      font-family: 'lilita';
      font-weight: 400;
	font-size: 4em;}


            
#maincontainerstuff .h2 {color: #fb8842;
  font-family: 'lilita';
  font-weight: 400;
  font-size: 3em;}

	

#nftcontainer {
            width: 100%;
            padding-top: 90px;
            padding-bottom: 90px;
            background: #f4efe9;}
            
#welcome {
            margin: auto;
            color: white; 
            display: flex; 
            align-items: center;
            padding-left: 60px; 
            padding-right: 60px;
            max-width: 1440px; }
            

#white {display: flex;
              gap: 24px;
              justify-content: flex-start;}
            
#white a {text-decoration: none;}
            
.bouton {color:#fb8842;
                display: inline-block;
                position: relative;
                font-weight: 700;
                font-family: poppins;
                padding-left: 2rem;
                font-size: 18px;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
                padding-right: 2rem;
                border-radius: 10px;
                border: 1px solid #fb8842;;}
            
            
.bouton:hover {color:white;
              background-color: #fb8842;}
            
            
              
#welcometext {flex-basis: 40%;
              background:#fb8842; 
              padding:  55px 45px 60px 60px;
              border-radius: 20px;}

#welcometext h2 {font-family: 'lilita';
              font-size: 3.2em;
              margin-top: 0;
              font-weight: 400;
              color: white;}
              
#welcomeimage {flex-basis: 60%;
              text-align: right;
              overflow: hidden;}

#mob1 {text-align: center;
                position: relative;
                margin-top: 36px;}


.tresor {position: absolute;
                top: 0;
                left: 50px;
                animation: tresor 2.5s ease-in-out alternate infinite;}
              
              
@keyframes tresor { from {transform: translateY(-20px); } to {transform: translateY(10px); } }
              
              
 #welcomeimage img {width: 80%;}


#staking {position :relative;
	background-color:white;
	padding-top: 90px;
	padding-bottom: 90px;}
	

#stakingcontainer {
	margin: auto;
	color: white; 
	display: flex; 
	align-items: center;
	overflow: hidden;
	padding-left: 60px; 
	padding-right: 60px;
	max-width: 1440px;}



#look {padding-top: 90px;
    padding-bottom: 90px;
    background:#f4efe9;}


#collec {padding-top: 90px;
    padding-bottom: 90px;
    color:#505079;
    background:white;}


#collec h2 {color: #fb8842;
      font-size: 4em;
      font-family: 'lilita';
      font-weight: 400;
      margin-top: 0px;
      text-align: center;}
  
#look h2 {color: #fb8842;
      font-size: 4em;
      font-family: 'lilita';
      font-weight: 400;
      margin-top: 0px;
      text-align: center;}

#titrecollec {font-family: 'lilita';
max-width: 400px;
margin: auto;
border: 2px solid #505079;
border-radius: 6px;
font-weight: 400;
font-size: 2.5em;
margin-bottom: 53px;}

#containerlook {max-width: 1440px;
      text-align:center;
      margin: auto;
      padding-left: 60px;
      padding-right: 60px;}


      
#containercollec {max-width: 1440px;
  position: relative;
  text-align:center;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;}


#lore {
	display: flex; 
  flex-direction: row;
	align-items: stretch;
	overflow: hidden;}

#loretext {flex-basis: 40%;
         text-align: left;
         background:  #634B40;
         color: white;
        font-size: 16px;
        padding: 55px 45px 60px 60px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;}

#loretext a {text-decoration: none;}

#loretext h2 {color:white;
  font-size: 3.2em;
  text-align: left;
  font-family: 'lilita';
  font-weight: 400;
  margin-top: 0px;}


 #loreimage {background-image: url("static/media/lore1.png");
  background-size: cover;	
  flex-basis: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 20px; 
  border-bottom-left-radius: 20px;
  overflow: hidden;}



#ency {width: 200px;
margin-top: 32px;
background: white;
margin-left: auto;
margin-right: auto;
font-size: 24px;
font-family: 'lilita';
text-align:center;
cursor: pointer;
padding: 16px;
color: #634B40;
border-radius: 12px;
transition: 0.3s;}


#ency:hover {color: #B69C90;}


	
#stakingimage {flex-basis: 60%;
    text-align:center;
    border-top-left-radius: 20px; 
    border-bottom-left-radius: 20px;
    overflow: hidden;}


#stakingimage img {width: 80%;}
  
#stakingtext {flex-basis: 40%;
    color:white;
    background:#fb8842;
    padding: 55px 45px 60px 60px;
    border-radius: 20px;}

  
#staking h2 {color:white;;
    font-size: 3.2em;
    font-weight: 400;
    font-family: 'lilita';
    margin-top: 0;}
  
  
#pause {height: 300px;
  background-image: url('static/media/pause.png');
  background-position: center;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;	
  width: 100%;
  position: relative;
  overflow: hidden;}

                
#diaporama {
  text-align: center;}

#first {padding-bottom: 90px;
        max-width: 640px;
        margin: auto;}

#first img {width: 100%;}


#second {display: flex;
  flex-direction: row;
	align-items: center;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;}

.nft {flex-basis: 30%;}

.nft img {width: 100%;
	    transition: all 0.2s ease;}

.nft img:hover{transform: scale(1.04);}

.number {background: #fb8842;
      font-family: lilita;
      margin-left: auto;
      margin-right: auto;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 1.3em;
      color:white;
      line-height: 40px;
      border-radius: 20px;}
    


#token {
	padding-top: 90px;
	padding-bottom: 90px;
	background: #f4efe9;}
	
	
#token h2 {font-size: 3.2em;
  font-family: 'lilita';
  color:white;
	font-weight: 400;
	margin-top: 0px;}
		
#containertoken {max-width: 1440px;
		padding-left: 60px;
		padding-right: 60px;
		margin: auto;}


#containertoken2 {display: flex; 	
      align-items: center;}	

#tokentxt {flex-basis: 40%; 
  border-radius: 20px;
  color: white;
  background-color: #fb8842;
  padding: 55px 45px 60px 60px;}

#tokenimage {flex-basis: 60%; 
margin-right: 45px;}


#roadmap {background-color: white;
	width: 100%;
	padding-top: 90px;
	padding-bottom: 90px;}

#team {background-color:#f4efe9;
    width: 100%;
    padding-top: 90px;
    padding-bottom: 210px;}

#roadmapcontainer {max-width: 1440px;
		display: flex;
		flex-direction: column;
		justify-content:center;
		align-items: center;
		padding-left: 30px;
		padding-right: 30px;
		margin: auto;}
    

#teamcontainer {max-width: 1440px;
    padding: 55px;
      display: flex;
      background-color: white;
      border-radius: 20px;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      margin: auto;}

#road {width: 54%;}

#road img {width: 100%;}

#hero {width: 100%;}

#hero img {width: 100%;}



#roadmap h2 {color: #fb8842;
    font-family: 'lilita';
    font-size: 4em;
    font-weight: 400;
		margin-top: 0px;
	  text-align: center;}

#team h2 {color: #fb8842;;
      font-family: 'lilita';
      font-size: 4em;
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;}


#collectioncontainer {display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  flex-wrap: wrap;
  flex-Direction: row;
  gap: 30px;
  justify-content: space-evenly;
  align-content: space-between;
  align-Items: flex-start;}


.lien {text-decoration: none;}

#collectioncard {
padding: 20px;
margin-Bottom: 10px;
background-color: white;
border-radius: 20px;
display: flex;
flex-grow: 1;
flex-direction: column;
justify-content: center;
transition: all 0.2s ease;
align-items: center;}

#collectioncard:hover {transform: scale(1.05);
        box-shadow: 0 0.3125rem 0.3125rem 0 rgba(0, 0, 0, 0.1)}

#cardimg img {
border-radius: 10px;}

.idnft {color:#fb8842;
  font-family: 'lilita';
font-size: 1.3em;
margin-top: 20px;
margin-bottom: 20px;}


@font-face {
  font-family: 'lilita';
  src: local('lilita'), url(./static/font/LilitaOne-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "poppins";
  src: local('poppins'), url(./static/font/Poppins-Regular.ttf) format('truetype');

}
  

header {background:#5ce1e6;
  z-index: 10;
  top: 0;
  width: 100%;}	


#site-header {
  max-width: 1450px;
  background-image: url('static/media/stonebar.png');
  background-position: 50%;
  background-repeat: none;
  background-size: 1450px 200px;
  height: 200px;
  margin: auto;}


.box {position: absolute;
  bottom: -1px;
  height: 40px;
  width: 100%;
  background-image: url("static/media/bordure.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  left: 0}

#flexheader {
  display: flex;
  max-width: 1220px;
  margin: auto;
  justify-content: space-between;}

#logo {padding-top: 8px;
  cursor: pointer;
  text-align: center;
  height: 72px;}

#popover {background-color: black;
    color: white;
    margin-top: 8px;
    cursor: pointer;
    font-size: 1.3em;
    padding: 10px 18px 10px 18px;
    margin-bottom: 14px;
    border-radius: 12px;
    font-family: 'lilita';}

.css-1uilhaa {z-index: 110;
width: 180px;}

.cobutton {
  margin-top: 8px;
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'lilita';
  font-size: 1.3em;
  background: black;
  border-radius: 12px;
  padding: 10px 18px 10px 18px;
  color: white;
  border: none;}


.text1 {display: block;
          color:white;
          margin-left: 20px;
          font-size: 1.7em;
          font-weight: 100;
          font-family: 'lilita';
          }
              
.text3  {color: gray;
  font-size: 1.3em;
  font-weight: 100;
  font-family: 'lilita';}    
      

section {position: relative;
	width: 100%;
  min-height: 800px;
	font-family: 'poppins';}


#container {padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 12px;
  border-top-left-radius: 0px;
  max-width: 1440px;
  background-color: #f4efe9;
  margin: auto;}

#flexcontainer {display: flex;
  align-items: center;}

#boxleft {width: 50%;
          text-align:center;}

#boxleft img {width: 80%;
        transition:  all 0.2S ease;}

#boxleft img:hover {transform: scale(1.08);}

#boxright {padding-top: 40px;
          padding-bottom: 40px;
          width: 46%;
          padding-left: 2%;
          padding-right: 2%;}

#titre {display: block;
      font-family: 'lilita';
      color:#fb8842;
      font-size: 3.75em;
      margin-bottom: 40px;}

#price {color: white;
        display: inline-block;
        padding: 8px 12px 8px 12px;
        font-size: 1.3em;
        font-family: lilita;
        border-radius: 8px;
        background-color: gray;}

#mint {color: white;
  margin-left: 20px;
  display: inline-block;
  padding: 8px 12px 8px 12px;
  font-size: 1.3em;
  font-family: lilita;
  border-radius: 8px;
  background-color: gray;}


#mintbutton button {color: #fb8842;
          font-family: 'lilita';
          background-color:white;
          cursor: pointer;
          border: 2px solid #fb8842;
          font-size: 1.3em;
          border-radius: 10px;
          padding: 10px 16px 10px 16px;
          transition: all 0.3s ease;}

#mintbutton button:hover {color: white;
            background-color:#fb8842;
            box-shadow: 0 0 16px #fb8842;}


#mainlock {width: 400px;
  box-sizing:border-box;}

#minttab {display: flex;
    gap: 20px;
    margin-top: 40px;
    align-items: center;}

#minttab input {height: 20px;
    border-radius: 8px;
    width: 100px;
    font-size: 1em;
    font-family: 'lilita';
    padding: 10px;
    border: 1px solid gray;}


#max {color:#fb8842;
      padding-top: 12px;
      font-family: 'lilita';
      font-size: 1.3em;}


#dashboardmenu {max-width: 1440px;
  padding-bottom: 60px;
  margin:auto;}
  

#tablist {padding-top: 20px;
        display: flex;
        gap: 20px;
        font-family: 'lilita';
        font-size: 1.3em;}

.tabs {cursor: pointer;
      padding-bottom: 12px;
      padding-left: 20px;
      color: white;
      border-radius: 12px 12px 0 0;
      background-color: #BB9C77;
      padding-right: 20px;
      padding-top: 12px;}

.active-tabs {color:#fb8842;
    background-color: #f4efe9;
  border: none;}



#maincontainerstuff {background-color:#f4efe9;
      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 24px;
      border-radius: 12px;
      border-top-left-radius: 0px;
      padding-right: 24px;}

#flexcontainerstuffcard {display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  }


.stuffadress {width: 50%;
      Background-color: #c8c8c8;
      border-radius: 20px;
      padding: 30px;}

.stuffadress a {text-decoration: none;
                color: white;}
    
    
.stuffbalance {width: 50%;
        Background-color: #c8c8c8;
        border-radius: 20px;
        padding: 30px;}    
      
    
.containerbalance {display: flex;
    align-items: center;}

.infosnfts {width: 100%;
  box-sizing: border-box;
    Background-color: #c8c8c8;
    border-radius: 20px;
    margin-bottom: 60px;
    padding: 30px;}

.infosnfts p {font-family: 'lilita';
      color: white;
    font-size: 1.3em;}

.titrewallet {font-size: 2em;
  font-family: 'lilita';
  color: #949494;}

#stakcontainer {background-color: #f4efe9;
          border-radius: 0px 12px 12PX 12PX;}

#work {display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    font-family: 'lilita';
    font-size: 1.3em;
    flex-direction: column;
    min-height: 800px;
    margin:auto;
    padding-top: 0px;}

  

.totop {width: 58px;
      height: 58px;
      display: none;
      z-index: 500;
      position: fixed;
      bottom: 20px;
      right: 30px;}

.discord {position: fixed;
      background-image: url("static/media/panneau.png");
      background-repeat: no-repeat;
      height:230px;
      width: 205px;
      bottom: 0px;
      right: 40px;}

	
	
.infos {text-align: center;
        padding-top: 40px;
        color: white;
        font-family: lilita;
        font-size: 16px;}
      
.totop img {width: 60px;
        height: 60px;}
      


footer {width: 100%;
  color: white;
  font-size: 1em;
  background-color: #222224;}


#footer {max-width: 1440px;
    font-family: poppins;
    min-height: 310px;
    display: flex;
    padding-top: 120px;
    padding-bottom : 120px;
    padding-left: 60px;
    padding-right: 60px;
    margin: auto;}
    

#colonne {flex-basis: 30%;}

#socialfooter {margin-top: 18px;}

#socialfooter svg {height: 20px;
	background-color: #fb8842;
	border-radius: 6px;
	padding: 10px;
	color: white;
	margin-right: 1rem;}


#socialfooter a {text-decoration: none;}


.item {border: 2px solid green;}

#nftidentifier {color:#949494;
  font-family: 'lilita';
  text-decoration: none;
}

#nonft {max-width: 400px;
  text-align:center;
  padding: 60px;
  margin: auto;
  background-color:#BB9C77;
  color: white;
  border-radius: 20px;
  font-family: 'lilita';
  font-size: 1.7em;}

.pop span {color: black;
display: flex;
align-items: center;
font-family: 'lilita';
font-size: 1.3em;}


.pop1 {color: black;
  padding: 15px 20px 15px 20px;
  width: 200px;
  margin: auto;
  display: flex;
  align-items: center;
  cursor:pointer; 
  justify-content: center;
  font-family: 'lilita';
  font-size: 2em;}


/* elrond dapp tools overwrites - there is no other way at the moment and I don't want to use react-bootstrap */
.card-body {
  text-align: center;
  border-radius: 16px;
  padding:32px;
  background-color:#fb8842;
  color: #222224;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-body h4.mb-4,
.card-body h4.mb-3 {
  font-size: 2.7em;
  font-family: 'lilita';
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

p.mb-4 {display: none;}

.card-body h4.mb-3 {
  margin-bottom: 10px;
}

.card-body p.lead.mb-0,
.card-body p.mb-4{
  text-align: center;
  padding-top: 20px;
  font-size: 1.3em;
  font-family: 'lilita';
  color: white;}

.card-body h4.mb-3 {color:white;
  font-family: 'lilita';
  padding-top: 20px;
  font-size: 2em;}


/*bouton de connexion*/

.card-body a.btn.btn-primary {
    color: #fb8842;
    box-sizing: border-box;
    font-size: 1.3em;
    font-family: 'lilita';
    text-decoration: none;
  }

.card-body .btn.btn-primary {
  margin-top: 30px;
  font-family: 'lilita';
  font-size: 1.3em;
  border: none;
  font-weight: 100;
  padding: 10px;
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  color: #fb8842;
}

.card-body .btn.btn-primary:hover {color: white;
  background-color:#222224;}

/* fin bouton de connexion*/


.state.m-auto.p-4.text-center {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* elrond dapp tools overwrites end */


.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}




.state.m-auto.p-4.text-center {
    width: auto;
    padding: 20px;
    height: auto;
    min-height: 320px;
}



#rightslide {display: none;
	position: fixed;
	height: 100%;
	width: 100%;
  overflow: hidden;
	top: -100vh;
	z-index: 10000;
	right: 0px;
	overflow: auto;
	background: white;}
					

  #fermer {padding: 12px;
    color:white;
    text-align:right;}

  #fermer img {color: red;}
  
  #fermer:hover {cursor: pointer;}	
  
  #menudroite {padding: 0;
    text-align: center;
    margin: 0;}
  
  #menudroite li {
  list-style-type: none;
  width: 50%;
  margin: auto;
  line-height: 80px;}
  
  #menudroite li a {text-decoration: none;
  font-family: lilita;
  font-size: 2em;
  color:#fb8842;
  transition: all 0.2s;
  display: block;}
  
  #menudroite li a:hover {color:black;}

  .button{
		margin-top: 30px;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
		text-align:center;
		cursor:pointer;
		font-family: lilita;
		font-size: 1.3em;
		background-color:#fb8842;
		border-radius: 12px;
		padding-top: 10px;
		padding-left: 18px;
		padding-right: 18px;
		padding-bottom: 10px;
		color: white;
		border: none;}

.popover {font-family: 'lilita';
        margin: auto;
        padding: 10px 15px 10px 15px;
        margin-bottom: 60px;
        border-radius: 10px;
        text-align:center;
        width: 200px;
        color:white;
        background-color:black;
      font-size: 1.3em;}


.home {width: 100%;}



@media(max-width: 1040px)

{
	
#nav {display: none;}
#right {display: none;}
.discord {display:none;}
#rightslide {display: block;}


#bouton_menu {display: block;}

}







@media(max-width: 720px)

{


#gem {animation: animate 2.5s ease-in-out 1s alternate infinite;}

  @keyframes animate { from {transform: translateY(0px); } to {transform: translateY(6px); } }
  
#sgem {animation: animate1 2.5s ease-in-out 1s alternate infinite;}
    
  @keyframes animate1 { from {transform: translateY(0px); } to {transform: translateY(-6px); } }


#dashboardmenu {padding-left: 10px;
  padding-top: 180px;
  padding-right: 10px;}


#containerlook {padding-left: 10px;
  padding-right: 10px;}

#flexcontainerstuffcard {flex-direction: column;
}

#flexcontainer {flex-direction: column;}

#boxleft {width:100%;}
#boxright {width: 100%;
text-align:center;}

.stuffadress {width: 100%;
  box-sizing: border-box}

.stuffbalance {width: 100%;
  box-sizing: border-box}


#minttab {justify-content:center;}
	
#arcadia h2 {font-size: 3em;}

#welcometext h2 {font-size: 3em;}


#collec H2 {font-size: 3em;}


.box {height: 20px;}

.box2 {height: 20px;}


#collection{margin-top: 60px;}

#logo img {width: 160px;}

#logo {padding-left: 16px;}

#arcadia1 {padding-left: 6px;
	flex-direction: column;
	padding-right: 6px;
	padding-top: 10px;}

#arcadia15 {display: none;}

#arcadia2 {width: 100%;
	text-align:center;}

#white {justify-content: center;
		flex-direction: column;}

#nftcontainer {padding-top: 60px;
	padding-bottom: 12px;}

#welcome {padding-left: 6px;
	padding-right: 6px;
	justify-content: center;
	align-items: center;
	padding-top: 6px;
	flex-direction: column;}

#welcometext {flex-basis: 100%;
	text-align: center;
	padding: 42px 24px 24px 24px;}

#mob1 {text-align:center;
  position: relative;
	margin-top: 36px;}

.tresor {top: -20px;
left: 40px;}

@keyframes tresor { from {transform: translateY(10px); } to {transform: translateY(30px); } }

#staking {padding-top: 60px;
	padding-bottom: 12px;}

#stakingcontainer {padding-left: 6px;
	padding-right: 6px;
	flex-direction: column-reverse;}

#lore {padding-left: 6px;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 6px;
  padding-right: 6px;}

#loretext {border-radius: 20px;}

#loreimage {height: 300px;
flex-basis: 100%;}


#loretext h2 {text-align: center;}

#staking h2 {font-size: 3em;}

#look h2 {font-size: 3em;}

#stakingtext {
			text-align:center;
			padding: 42px 24px 12px 24px;}


#stakingimage {padding-bottom: 36px;}

#token h2 {font-size: 3em;}

#roadmap h2 {font-size: 3em;}

#team h2 {font-size: 3em;}

#look {padding-top: 60px;
	padding-bottom: 12px;}


#titrecollec {font-family: 'lilita';
    max-width: 400px;
    margin: auto;
    border: 2px solid #505079;
    border-radius: 6px;
    font-weight: 400;
    font-size: 2em;
    margin-bottom: 53px;}


#diaporama {padding-top: 60px;
		text-align :center;}
	

#first {padding-bottom: 90px;}


#second {flex-direction: column;
		gap: 24px;}

.nft { gap: 10px;}

.float img {width: 100%;}

.stone img {width: 40%;}

.number {margin-bottom: 16px;}

#token {padding-top: 60px;
	padding-bottom: 12px;}

#containertoken {padding-left: 6px;
	padding-right: 6px;}

#tokentxt {text-align: center;
	padding: 42px 24px 24px 24px}

#tokenimage {margin-right: 0px;}

#roadmapcontainer {padding-left: 6px;
	padding-right: 6px;}

  #teamcontainer {padding-left: 6px;
    padding-right: 6px;}

#road {width: 100%;}

#footer {text-align: center;
		padding: 60px 30px 60px 30px;}

#colonne {flex-basis: 100%;}


#containertoken2 {flex-direction:column-reverse;}	


#mint {margin-left: 0px;
margin-top: 20px;}

#titre {font-size: 2em;}

#maincontainerstuff .h2 {font-size: 2em;}

#mainlock {width: 320px;
margin-left: auto;
margin-right: auto;}

.card-body {
  box-sizing: border-box;
}

.card-body > div:nth-child(3) {
  width: 100%;
 }
 

}
